// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import GlossaryLink from '../../components/glossary-link';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import CanyonRatingCard from '../../components/canyon-rating-card';
import AllImagesLink from '../../components/all-images-link';
import Link from '../../components/link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _DSF8544: ImageType,
    _DSF8549: ImageType,
    _DSF8559: ImageType,
    _DSF8574: ImageType,
    _DSF8579: ImageType,
    _DSF8581: ImageType,
    _DSF8587_panorama: ImageType,
    _DSF8591_panorama: ImageType,
  },
};

class Blog_2018_09_22_Rock extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <CanyonRatingCard rating="3AII" title={frontMatter.title} />
        <p>
          Day 3 of the Zion Canyon Rendezvous I did Rock Canyon while the rest
          of my group went and did Pine Creek. Shirlz of{' '}
          <Link to="http://www.store.canyoneeringusa.com/">
            Imlay Canyon Gear
          </Link>{' '}
          would be guiding/leading the group. I knew my vehicle wouldn't ever be
          able to make it down the long dirt roads and so I jumped at the chance
          to meet Shirlz and finally get to see this canyon.
        </p>
        <Image image={data._DSF8544} caption="Group shot at the trailhead" />
        <p>
          After setting up a short shuttle we headed down into the canyon where
          it slotted up and presented us with our first set of{' '}
          <GlossaryLink>rappels</GlossaryLink> for the day.
        </p>
        <Image
          image={data._DSF8549}
          caption="Getting ready to go down the first rappel"
        />
        <Image
          image={data._DSF8559}
          caption="Fun down climb right after a rappel"
        />
        <p>
          I was in the back pulling and stuffing ropes so I didn't get to see
          that the fourth rappel was rigged short and the first rappeller had to
          be <GlossaryLink id="lower">lowered</GlossaryLink>. Fortunately we
          rigged all rappels with a{' '}
          <GlossaryLink>contingency rigging</GlossaryLink> system so lowering
          was simple.
        </p>
        <Image image={data._DSF8574} caption="Fourth rappel" />
        <p>
          After rap 4 we stopped for lunch. That alcove was so beautiful! I
          didn't even realize it while I was in there because I was so focused
          on my chips, beans, and salsa. It wasn't until we left and I turned
          around that I realized what an amazing place I was in.
        </p>
        <Image image={data._DSF8579} caption="Rap 4 alcove" />
        <p>
          After a bit of hiking we arrived at the final rappel where we
          de-rigged and took this group photo:
        </p>
        <Image image={data._DSF8581} />
        <p>
          At the end of the canyon there are some spectacular{' '}
          <GlossaryLink>petroglyph</GlossaryLink> panels. Even without the
          technical canyon these panels were worth the trip.
        </p>
        <Image image={data._DSF8587_panorama} />
        <Image image={data._DSF8591_panorama} />
        <p>
          The hiking ended with a brisk climb up the hill and to our awaiting
          shuttle vehicle. I'm so thankful I went to the rendezvous this year,
          met some great people, and was able to visit so many wonderful
          canyons.
        </p>
        <AllImagesLink id="2018-09-22-rock-canyon" />
      </Layout>
    );
  }
}

export default withFrontMatter('2018-09-22-rock-canyon')(Blog_2018_09_22_Rock);

export const query = graphql`
  query {
    _DSF8544: file(
      relativePath: { eq: "images/blog/2018-09-22-rock-canyon/_DSF8544.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8549: file(
      relativePath: { eq: "images/blog/2018-09-22-rock-canyon/_DSF8549.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8559: file(
      relativePath: { eq: "images/blog/2018-09-22-rock-canyon/_DSF8559.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8574: file(
      relativePath: { eq: "images/blog/2018-09-22-rock-canyon/_DSF8574.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8579: file(
      relativePath: { eq: "images/blog/2018-09-22-rock-canyon/_DSF8579.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8581: file(
      relativePath: { eq: "images/blog/2018-09-22-rock-canyon/_DSF8581.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8587_panorama: file(
      relativePath: {
        eq: "images/blog/2018-09-22-rock-canyon/_DSF8587_panorama.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8591_panorama: file(
      relativePath: {
        eq: "images/blog/2018-09-22-rock-canyon/_DSF8591_panorama.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
